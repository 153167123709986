<template>
  <div class="locale-select-sheet">
    <b-dropdown
      id="dropdown-grouped"
      right
      variant="link"
      class="dropdown-language lang-drop"
    >
      <template #button-content>
        <span class="d-flex align-items-center">
          <span class="ml-50 text-body">{{
            currentLocale.locale.slice(0, 2).toUpperCase()
          }}</span>
        </span>
      </template>
      <b-dropdown-item
        v-for="localeObj in locales"
        :key="localeObj.locale"
        @click.stop="changeLocale(localeObj.locale)"
      >
        <!-- <b-img
          :src="localeObj.img"
          height="22px"
          width="22px"
          :alt="localeObj.locale"
        /> -->
        <span class="ml-50">{{ localeObj.name }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BImg } from "bootstrap-vue";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BImg
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    }
  },

  methods: {
    changeLocale(newLocale) {
      // save locale to local storage
      localStorage.setItem("appLocale", newLocale);
      this.updateQueryParameters(newLocale);
    },
    updateQueryParameters(newLocale) {
      const currentPath = window.location.pathname;

      const languageCodeRegex1 = new RegExp(`^/${newLocale}(\/|$)`);
      const isLanguageAvailable = languageCodeRegex1.test(currentPath);

      if (!isLanguageAvailable) {
        const languageCodeRegex = /^\/[a-zA-Z]{2}(\/|$)/;
        const hasLanguageCode = languageCodeRegex.test(currentPath);

        let newPath;

        if (hasLanguageCode) {
          newPath = currentPath.replace(languageCodeRegex, `/${newLocale}/`);
        } else if (currentPath === "/") {
          newPath = `/${newLocale}`;
        } else {
          newPath = `/${newLocale}${currentPath}`;
        }

        window.history.pushState({}, "", newPath);

        window.location.reload();
      }
    }
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        // img: require("@/assets/images/flags/en.png"),
        name: "English"
      }
      // {
      //   locale: "fr",
      //   img: require("@/assets/images/flags/fr.png"),
      //   name: "French"
      // },
      // {
      //   locale: "de",
      //   img: require("@/assets/images/flags/de.png"),
      //   name: "German"
      // }
    ];
    /* eslint-disable global-require  used lang drop*/

    return {
      locales
    };
  }
};
</script>
<style lang="scss" scoped>
.img-fluid1 {
  visibility: visible;
  opacity: 1;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #7268ef;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  transition: all 0.4s;
}
.img-fluid12 {
  object-fit: cover; /* Maintain the image aspect ratio and cover the container */
  border-radius: 20px;

  width: 30px;
  height: 30px;
}
</style>
<style lang="scss">
.lang-drop {
  .dropdown-toggle {
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    width: 60px;
  }
  .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
  }
}
</style>
